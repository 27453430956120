import { Box, Button, Typography, useMediaQuery, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Heading from '../components/Heading';
import Image from '../components/Image';
import { Link, useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { T } from '../Globals';

const fetchMarkersAndMicroImages = async (activeImage, markers) => {
  const microImagesPromises = markers.map(marker => 
    fetch(`/api/qpmicrosbymarker/${activeImage}/${marker._id}`).then(res => res.json())
  );
  const microImagesResults = await Promise.all(microImagesPromises);
  const microImages = microImagesResults
    .filter(result => result.result === "ok")
    .map(result => result.data)
    .flatMap(el => el);
  return microImages;
};

const chunkArray = (array, size) => {
  return Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
    array.slice(index * size, (index + 1) * size)
  );
};

export default function QPPatientItem2() {
  const { id } = useParams();
  const [activeImage, setActiveImage] = useState({
    id: '', 
    isMicro: false,
  });
  const [state, setState] = useState({
    mainImages: [],
    markers: [],
    microImages: [],
  });
  const [loading, setLoading] = useState(true); 
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [soLinkData, setSOLinkData] = useState({medReferenceId: null, medPointId: null});
  const [shouldFetch, setShouldFetch] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); 
        let imageId = activeImage.id;
        let baselineData = {};
        
        if (!imageId) {
          const baselineResult = await fetch(`/api/qpbaseline/${id}`).then(res => res.json());
          if (baselineResult.result !== "ok") return;
          baselineData.mainImages = baselineResult.data;
          imageId = baselineResult.data[0]._id;
          setActiveImage({ id: imageId });
        }

        const markersResult = await fetch(`/api/qpmarkers/${imageId}`).then(res => res.json());
        if (markersResult.result !== "ok") return;

        setState(prev => ({
          ...prev,
          ...baselineData,
          markers: markersResult.data,
        }));

        if (shouldFetch) {
          const microImages = await fetchMarkersAndMicroImages(imageId, markersResult.data);
          setState(prev => ({
            ...prev,
            microImages,
          }));
          setShouldFetch(false); 
        }

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);  
      }
    };
    fetchData();
  }, [id, activeImage.id, shouldFetch]);

  const size = isMobile ? 2 : 6;
  const prepareBaseline = chunkArray(state.mainImages, size);
  const prepareMicro = chunkArray(state.microImages, size);

  const handleClickImage = (medReferenceId, medPointId, imageId, isMicro) => {
    setActiveImage({id: imageId, isMicro: isMicro});
    [medReferenceId, medPointId] = [Number(medReferenceId), Number(medPointId)]; 
    (medReferenceId && medPointId && medPointId !== -1)
      ? setSOLinkData({medReferenceId, medPointId})
      : setSOLinkData({medReferenceId: null, medPointId: null})  
  }

  return (
    <Box sx={{ maxWidth: window.maxWidth, width: "100%", margin: "20px auto", px: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <Heading heading={<T>QP picture patient</T>} style={{ marginBottom: 0, paddingBottom: 0 }} />
      <Box sx={{ borderBottom: "none", padding: "0", marginTop: 2}}>
        {loading ? (  
          <CircularProgress/>
        ) : (
          <Image
            src={activeImage.id ? `/api/qpimage/${activeImage.id}` : ""}
            alt="Main Image"
            markers={activeImage.isMicro ? [] : state.markers}
            activeImage={activeImage.id}
            mainImages={state.mainImages}
          />
        )}
      </Box>
      {(soLinkData.medReferenceId && soLinkData.medPointId) && 
        <Link to={`/qpsorequest/${soLinkData.medReferenceId}/${soLinkData.medPointId}`} className='mt-4 mx-auto'><Button color={"primary"}>Запросить второе мнение</Button></Link>
      }
      {prepareBaseline.length > 0 &&
        <Box sx={{ mt: 2, width: "100%", mx: "auto", maxWidth: isMobile ? "300px" : "100%" }}>  
          <Typography variant='body2' color='primary' sx={{ mb: 1 }} textAlign={"left"}><T>QP overview</T></Typography>
          <Slider imagesData={state.mainImages} activeImage={activeImage.id} handleClickImage={handleClickImage} isMicro={false} isMobile={isMobile} />
        </Box>
      }
      {prepareMicro.length > 0 &&
        <Box sx={{ mt: 2, width: "100%", mx: "auto", maxWidth: isMobile ? "300px" : "100%" }}>
          <Typography variant='body2' color='primary' sx={{ mb: 1 }} textAlign={"left"}><T>QP microshot</T></Typography>
          <Slider imagesData={state.microImages} activeImage={activeImage.id} handleClickImage={handleClickImage} isMicro={true} isMobile={isMobile} />
        </Box>  
      }
    </Box>
  )
}

function Slider({ imagesData, markers, isMobile, isMicro, activeImage, handleClickImage }) {
  return (
    <Splide options={{ rewind: true, perPage: isMobile ? 5 : 8, perMove: 1, pagination: false }}>
      {
        imagesData?.map((el, i) => {
          const m = markers?.find(m => m._id === el.MedPoint);
          return (
            <SplideSlide key={i} className="flex justify-center relative">
              <img onClick={() => handleClickImage(el?.MedReference, el?.MedPoint, el?._id, isMicro )} className={`${isMobile ? "w-8" : "w-20"} h-fit m-auto cursor-pointer ${el._id === activeImage && "border-4 border-secondary border-solid"}`} src={`${'/api/qpicon/' + el?._id}`} alt={"Image " + i} />
              {!isMicro && m && <Typography variant='body2' sx={{ textAlign: "center" }} className='absolute p-1 bg-black bottom-0 pointer-events-none' color={"secondary"}>{m?.MarkText}</Typography>}
            </SplideSlide>    
          );
        })
      }
    </Splide>
  )
}