import React, { useState, useEffect, createContext, useContext} from 'react';
import { T,t,axios, Alert , naming, formatNumber, technicalСonnectionPalette} from "../Globals.js"
import { Stack, Slider, Box, Typography, Paper,Button,FormControlLabel,Checkbox,Table,TableHead,TableBody,TableCell,TableContainer,TableRow, FormHelperText, } from '@mui/material';
import {Alert as MaterialAlert} from '@mui/material';
import ItemTemplate from "../components/ItemTemplate";
import LinkField from '../components/LinkField.js';
import { useLocation, useParams } from "react-router-dom";
import { Link as RouterLink } from 'react-router-dom';
import Heading from '../components/Heading.js';
import ToPdfButton from '../components/ToPdfButton.js';
import dayjs from 'dayjs';

import {ReactComponent as IconBank} from "../icons/bank.svg";
import {ReactComponent as IconCreditCard} from "../icons/creditcard.svg";
import useValidation from '../hooks/useValidation.js';

const calcApi = (data, callback) => {
  axios.post("/api/transactions/calc", data).then(resp => callback(resp));
}

let initialTransactionState = {
  amount_rub: 0,
  amount_so: 0,
  price_so: 0,
  paymentmethod: null,
  status: null,
  type: null,
  id: null,
}; 

const TransactionContext = createContext(initialTransactionState);

const soVariants = [0,3,5,10,20,50]; 
const marksSO = soVariants.map((x,i)=>{return {value:i, label:t(x)}});


export function TransactionsItem(props) {
  let [data, setData] = useState({});

  const createHandler = async (data) => {
    if (data?.yookassa?.confirmation?.confirmation_url) {
      document.location.href=data.yookassa.confirmation.confirmation_url;
    } else {
      document.location.href = `/transactions/${data._id}`
    }
  }

  /* Сюда передается transaction при нажатии на кнопку Оплатить/Сформировать счет */
  const saveHandler = async (d) => {
    if (d) Object.keys(d).forEach((k) => data[k] = d[k]);
  }
  const applyHandler = async (d) => {
    if (d) Object.keys(d).forEach((k) => data[k] = d[k]);
  }

  const content = () => {
    if (data?.type === "spending") return <TransactionsItemSpending />
    
    return (
      <TransactionItem data={data}/>
    )  
  }

  return (
    <>
      <ItemTemplate {...props}  defaults={{unlimitedFF:undefined, paymentmethod: window.globalState?.user?.role_administrator ? null : "card"}} table="transactions" data={data} setData={setData} onCreate={createHandler} onSave={saveHandler} onApply={applyHandler} buttons={["close"]}>
        {content()}
      </ItemTemplate>
    </>
  )
}


export function TransactionItem(prop) {
  const [transaction, setTransaction] = useState(initialTransactionState);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("create")) setTransaction({...initialTransactionState, id: "create", type: "create"})
    if (location.pathname.includes("createFF")) setTransaction({...initialTransactionState, id: "createFF", type: "createFF"})
    if (!location.pathname.includes("create") && !location.pathname.includes("createFF")) setTransaction({...initialTransactionState, ...prop.data})
  }, [prop.data, location.pathname]);

  const content = () => {
    if (transaction.status) {return <TransactionsItemView {...prop}/>}
    if (transaction.type === "createFF") {return <TransactionsItemFotofinder2 {...prop}/>} 

    return <TransactionsItemDeposit2 {...prop}/>
  }

  

  return (
    <TransactionContext.Provider value={{transaction, setTransaction}}>
      {content()}   
    </TransactionContext.Provider>
  );
}


export function TransactionsItemView(prop) {

  const {transaction} = useContext(TransactionContext);

  const rows = {
    "qplicense": [
      {t: <T>onetime tariff</T>, v: transaction?.qplicenseperiod},
      {t: <T>QP count devices</T>, v: transaction?.qplicensecount},
      {t: <T>bill status</T>, v: transaction?.qpbillstatus},
    ],
    "qpprolong": [
      {t: <T>onetime tariff</T>, v: transaction?.qplicenseperiod},
      {t: <T>QP count devices</T>, v: transaction?.qplicensecount},
      {t: <T>bill status</T>, v: transaction?.qpbillstatus},
    ],
    "qpadd": [
      {t: <T>QP count devices</T>, v: transaction?.qpaddcount},
      {t: <T>bill status</T>, v: transaction?.qpbillstatus},
    ],
    "deposit": [
      {t: <T>amount so</T>, v: transaction?.amount_so},
    ],
    "unlimitedFF": [
      {t: <T>devices</T>, v: transaction?._dermatoscopes?.map(el => el.name).join(", ")},
    ],
  }

  return (
    <Stack sx={{gap:5, flexDirection: {xs:"column", md:"row"},   justifyContent: "center", mt:2}}>    
      <Paper variant="outlined" square sx={{ px:2, p:4, display: "flex", flexDirection: "column", backgroundColor: window.theme.palette.white.v2, borderStyle: "none"}}>
        <Typography component="div" variant='myDefault22' mb={1}>{<T>order</T>} {transaction.type}</Typography>

        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className='p-1'><Typography><T>date</T>:</Typography></TableCell>
                <TableCell className='p-1'><Typography>{dayjs(transaction.date).format("DD.MM.YYYY HH:mm")}</Typography></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='p-1'><Typography><T>transaction status</T>:</Typography></TableCell>
                <TableCell className='p-1'><Typography><T>{"transaction status " + transaction.status}</T></Typography></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='p-1'><Typography><T>transaction method</T>:</Typography></TableCell>
                <TableCell className='p-1'><Typography><T>{transaction?.paymentmethod}</T></Typography></TableCell>
              </TableRow>


              {rows[transaction.type]?.map(({t, v}, k) => (
                <TableRow key={transaction.type + String(k)}>
                  <TableCell className='p-1'><Typography>{t}:</Typography></TableCell>
                  <TableCell className='p-1'><Typography><T>{v}</T></Typography></TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>
        </TableContainer>
        <br/>
        <Itog prop={prop} transaction={transaction}/>

      </Paper>
    </Stack>
  );
}


export function TransactionsItemDeposit2(prop) {

  const {transaction, setTransaction} = useContext(TransactionContext);

  useEffect(() => {
    if (transaction?.type === "create") {
      axios.post("/api/transactions/calc", transaction).then(resp => setTransaction({...transaction, ...resp.data.data}));
    }
    // eslint-disable-next-line
  }, []);
  
  const handleChangeSlider = (event) => {
    if (event.target.name === "amount_so") setTransaction({...transaction, "amount_so": soVariants[event.target.value]});
  }

  const handleChangeCommitted = async () => {
    await calcApi(transaction, (resp) => setTransaction({...transaction, ...resp.data.data}))
  };

  return (
    <Stack sx={{gap:5, flexDirection: {xs:"column", md:"row"},   justifyContent: "center", mt:2}}>
      <Stack sx={{gap:2, flex:1}}>
        <Heading heading={<T>tariffs pay ai</T>} subheading={<T>onetime tariffs descr</T>} style={{margin: 0}}/>
        <Stack sx={{flexDirection: "column", gap: 3}}>
        {(window.features.so || prop.data?._id) && (
          <Box>
            <Stack sx={{flexDirection: "row", justifyContent: "space-between"}}>
              <Typography variant="h6" component="div">«<T>balance so</T>»</Typography>
              <Typography sx={{display: "flex", alignItems: "end"}} variant="body2" component="div">{"1 запрос = "}{formatNumber(transaction.price_so)}{"р"}</Typography>
            </Stack>
            <Slider
              value={soVariants.indexOf(transaction.amount_so) || 0}
              name="amount_so"
              onChange={handleChangeSlider}
              onChangeCommitted={(event, value) => handleChangeCommitted("amount_so", value)}
              getAriaValueText={value => value}
              step={1}
              min={0}
              max={soVariants.length-1}
              marks={marksSO}
              sx={{mt:1, ml:"10px", width:"calc(100% - 20px)"}}
              disabled={transaction.type === "create" ? false : true}
            />
          </Box>
        )}
        </Stack>
      </Stack>
      
      <Paper variant="outlined" square sx={{ px:2, p:4, flex: 1, display: "flex", flexDirection: "column", backgroundColor: window.theme.palette.white.v2, borderStyle: "none"}}>
        <Typography component="div" variant='myDefault22' mb={1}>{<T>order</T>}</Typography>
        <TableContainer>
          <Table sx={{}}>
            <TableHead>
              <TableRow>
                <TableCell sx={{p: 1, pl: 0, width:150}}><T>denomination</T></TableCell>
                <TableCell align='right' sx={{p: 1}}><T>count</T></TableCell>
                <TableCell align='right' sx={{p: 1}}><T>price</T></TableCell>
                <TableCell align='right' sx={{p: 1}}><T>sum</T></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(window.features.so || transaction?._id) &&
                <TableRow>
                  <TableCell sx={{p: 1, pl: 0}}><T>transactions create socount</T>:</TableCell>
                  <TableCell align='right' sx={{p: 1}}>{transaction.amount_so}</TableCell>
                  <TableCell align='right' sx={{p: 1}}>{formatNumber(transaction.price_so)}</TableCell>
                  <TableCell align='right' sx={{p: 1}}>{formatNumber((transaction.amount_so * transaction.price_so))}</TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>

        <FinishContent prop={prop} transaction={transaction} setTransaction={setTransaction}/>
      </Paper>
    </Stack>
  );
}

export function TransactionsItemFotofinder2(prop) {

  const [fflist, setFflist] = useState(null);
  const {transaction, setTransaction} = useContext(TransactionContext);
  const {myerrors} = useValidation(transaction, ["dermatoscopes"]);
  const location = useLocation();

  useEffect(() => {
    // eslint-disable-next-line
    const matches = location.pathname.match(/\/([^\/]+)$/);
    return () => prop.setData({id: matches[1]});
    // eslint-disable-next-line
  },[location.pathname]);

  useEffect(()=>{
    axios.get("/api/dermatoscopes").then(resp => {
      if (resp.data.result === "ok") setFflist(resp.data.data)
      else {
        Alert(resp.data.message)
      }
    })
  },[]);

  useEffect(() => {
    calculateRequest();

    async function calculateRequest() {
      await calcApi(transaction, (resp) => setTransaction({...transaction, ...resp.data.data}));
    } 
    // eslint-disable-next-line
  },[transaction?.dermatoscopes?.length, transaction.unlimitedFF]);

  return (
    <Stack sx={{gap:5, flexDirection: {xs:"column", md:"row"},  width:"100%", justifyContent: "center", mt:2}}>
      <Stack sx={{gap:0, flex:0.8}}>
        <Heading heading={<T>unlimited tariffs</T>} style={{margin: 0}}></Heading>
        <Typography component="pre" sx={{whiteSpace: "pre-line"}}><T>unlimitedFF description</T></Typography>
        <Typography component="pre" sx={{whiteSpace: "pre-line", mt:3, "strong": {color: "gray"}}}><strong><T>attention</T>!</strong> <T>unlimitedFF description 2</T></Typography>
        <Typography component="pre" sx={{whiteSpace: "pre-line", mt:3}}><T>unlimitedFF description 3</T></Typography>
        <Typography component="pre" sx={{whiteSpace: "pre-line", mt:3, "strong": {color: "gray"}}}><strong><T>unlimitedFF description 4</T></strong></Typography>
      </Stack>
      
      <Paper variant="outlined" square sx={{ p:4,flex:1, display: "flex", flexDirection: "column", backgroundColor: window.theme.palette.white.v2, borderStyle: "none"}}>
        <Typography component="div" variant='myDefault22'><T>make payment ff</T></Typography>
        <Stack>
          <FormHelperText error>
            {myerrors?.includes("dermatoscopes") ? <T>required</T> : ""}
          </FormHelperText>
          {fflist && fflist.length > 0 && fflist.map((ff, index) => {
            
            return (
              <>
              <FormControlLabel
                key={ff._id}
                checked={transaction?.dermatoscopes?.indexOf(ff._id) >= 0 || false}
                name="dermatoscopes"
                control={<Checkbox />}
                label={<>{naming(ff, 'dermatoscopes')}{'\u00A0\u00A0\u00A0'}{<small Style={`font-style: italic; font-weight: bold; color:${technicalСonnectionPalette[ff?._availabilitystatus]};`}><T>{ff?._availabilitystatus}</T></small>}</>}
                disabled={!!transaction?._id}
                onChange={(event) => {
                  if (!transaction?.dermatoscopes) transaction.dermatoscopes = [];
                  // Если стоит галочка
                  if (event.target.checked) {
                    const index = transaction.dermatoscopes.indexOf(ff._id);
                    if (index < 0) transaction.dermatoscopes.push(ff._id);
                  } else {
                    // Галочка была убрана
                    const index = transaction.dermatoscopes.indexOf(ff._id);
                    if (index >= 0) transaction.dermatoscopes.splice(index, 1);
                  }
                  if (!transaction.dermatoscopes.length) delete transaction.dermatoscopes;
                  
                  if ((transaction?.paymentmethod === "trial" || transaction?.unlimitedFF === "trial 30") && ff?._availabilitystatus !== "isnotpaid") {
                    setTransaction({...transaction, paymentmethod:"card", unlimitedFF:"quartal"});
                  } else {
                    setTransaction({...transaction});
                  }
                }}
              />
              </>
            )
          })}

          <FFBtn fflist={fflist}/>
          <TariffPeriod prop={prop} transaction={transaction} setTransaction={setTransaction} fflist={fflist}/>
          <br/>
          <FinishContent prop={prop} transaction={transaction} setTransaction={setTransaction}/>
        </Stack>
      </Paper>
    </Stack>
  )
}

export function TransactionsItemSuccess(prop) {
  const params = useParams();
  let id = prop.data?._id || prop.id || params.id
  const [status, setStatus] = useState("");
  const [counter, setCounter] = useState(0);
  //console.log("111111111 "+status+" "+counter)
  useEffect(()=>{
    if (counter < 50) {
      axios.get("/api/transactions/" + id+"/checkpayment").then((resp)=>{
        if (resp.data.result === "ok") {
          if (resp.data.data.status === "completed") {
            setTimeout(()=>{window.navigate("/transactions/"+id)}, 3000)
          } else {
            setTimeout(()=>{setCounter(counter+1)}, 10000)
          }
          setStatus(resp.data.data.status)
        }
      })
    }
  },[counter,id]);
  return (<>
    {status==="completed" && <T>paid success</T>}
    {status==="pending" && <T>paid pending</T>}
    
  </>)
}

function TransactionsItemSpending(prop) {

  useEffect(() => {
    // eslint-disable-next-line
    const matches = location.pathname.match(/\/([^\/]+)$/);
    return () => prop.setData({id: matches[1]});
    // eslint-disable-next-line
  },[location.pathname]);
 
  return (
    <Stack sx={{gap:3}}>
      <TableContainer >
        <Table sx={{maxWidth:"300px"}}>
          <TableBody>
            <TableRow>
              <TableCell><T>transactions create socount</T>:</TableCell>
              <TableCell>{prop.data?.amount_so}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><T>transactions create aicount</T>:</TableCell>
              <TableCell>{prop.data?.amount_ai}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><T>amount rub</T>:</TableCell>
              <TableCell>{prop.data?.amount_rub}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box> 
        <LinkField data={prop.data} table="requests" field="_request" label={<T>request item</T>} name="requestid" readOnly={true} />
      </Box>
    </Stack>
  )
}


function FFBtn({fflist}) {
  return (
    <>
    {!fflist || fflist.length === 0 ? 
      <Button onClick={()=>{window.navigate("/dermatoscopes")}} sx={{width: "fit-content"}}><T>ff please create</T></Button>
      :
      <Button onClick={()=>{window.navigate("/dermatoscopes")}} sx={{width: "fit-content"}} size="small" variant="text"><T>ff list manage</T></Button>
    }
    </>
  )
}

function TariffPeriod({prop, transaction, setTransaction, fflist}) {
  
  const getButtonsProps = (value, name) => {
    return  transaction[name] === value ? {variant: "contained", color: "secondary"} : {variant: "outlined", color: "grey"};
  }
  const getTitle = () =>  {
    if (prop?.data?._id) {
      return "selected tariff";
    }
    return transaction?.status ? "selected tariff" : "select tariff";
  } 
  const checkSelectedDermatoscopeTrial = () => {
    const paidffs = fflist?.filter(ff => ff?._availabilitystatus !== "isnotpaid").map(ff => ff._id);
    if (paidffs?.length) {
      for (let index = 0; index < paidffs.length; index++) {
        if (transaction?.dermatoscopes?.includes(paidffs[index])) {
          return true;
        }
      }
    }

    return false;
  }

  const getBtnQuartal = () => {
    return (
      <Button 
          {...getButtonsProps("quartal","unlimitedFF")} 
          sx={{width: "40%", fontWeight: 600}}
          name="unlimitedFF" 
          value="quartal" 
          onClick={()=>transaction.status ? null : setTransaction({...transaction, unlimitedFF:"quartal", paymentmethod:  transaction?.paymentmethod === "trial" ? null : transaction?.paymentmethod})}
        >
          <T>90 days</T>
      </Button>
    );
  }

  const getBtnYear = () => {
    return (
      <Button
        {...getButtonsProps("year","unlimitedFF")} 
        sx={{width: "40%", fontWeight: 600}}
        name="unlimitedFF" 
        value="year" 
        onClick={()=>transaction.status ? null : setTransaction({...transaction, unlimitedFF:"year", paymentmethod: transaction?.paymentmethod === "trial" ? null : transaction?.paymentmethod})}
      >
      <T>year</T>
    </Button>
    );
  }

  const getBtnTrial30 = () => {
    if (checkSelectedDermatoscopeTrial()) {
      return;
    }

    return (
      <Button
        {...getButtonsProps("trial 30","unlimitedFF")} 
        sx={{width: "40%", fontWeight: 600}}
        name="unlimitedFF" 
        value="trial 30" 
        onClick={()=>transaction.status ? null : setTransaction({...transaction, unlimitedFF:"trial 30", paymentmethod: transaction?.paymentmethod})}
        children={(d) => console.log(d)}
      >
      <T>payment method trial</T><br/><T>30 days</T>
    </Button>
    );
  }

  return (
    <>
      <Typography component="div" variant='myDefault18' color={"black"} sx={{mt:2, mx: "auto"}}><T>{getTitle()}</T></Typography>
      <Stack sx={{flexDirection: "row", justifyContent: "space-around", mt: 1, gap: "1rem"}}>

        {!transaction.status && (
          <>
            {getBtnTrial30()}
            {getBtnQuartal()}
            {getBtnYear()}
          </>
        )}
      </Stack>
    </>
  );
}

function FinishContent({prop, transaction, setTransaction, myerrors}) {

  const getCompletedAlert = () => {
    return (
      <MaterialAlert severity="info" color='warning'>
        <Typography><T>payment successful</T></Typography>
        <Typography><T>contact yourself</T></Typography>
        <br/>
        <Typography><T>questions contacts</T>: <RouterLink to={"/contacts"}><T>contacts</T></RouterLink></Typography>
      </MaterialAlert>
    );
  };

  return (
    <>
      <Box mt={2}>
        <Itog prop={prop} transaction={transaction}/>
      </Box>
      
      {(!transaction?.status) && (
        <>
        <VariantPaymentButtons prop={prop} transaction={transaction} setTransaction={setTransaction}/>
        <VariantPaymentText prop={prop} transaction={transaction}/>
        {getFinishBtn([prop, transaction, myerrors])}
        </>
      )}

      {transaction?.type==="deposit" ? 
        <></> 
        :
        <>
        {(transaction?.paymentmethod==="card" && transaction?.status==="completed") && (
          <><br/>{getCompletedAlert()}</>
        )}
        {(transaction?.paymentmethod==="bank" && transaction?.status==="completed") && (
          <><br/>{getCompletedAlert()}</>
        )}
        {(transaction?.paymentmethod==="trial" && transaction?.status==="completed") && (
          <>
            <br/>
            <MaterialAlert severity="info" color='warning'><Typography><T>wait confirmation payment 2</T></Typography>
              <br/>
              <Typography><T>questions contacts</T>: <RouterLink to={"/contacts"}><T>contacts</T></RouterLink></Typography>
            </MaterialAlert>
          </>
        )}
        {(transaction?.paymentmethod==="bank" && transaction?.status==="pending") && (
          <>
            <br/>
            <MaterialAlert severity="info" color='warning'><Typography><T>wait confirmation payment</T></Typography>
              <br/>
              <Typography><T>questions contacts</T>: <RouterLink to={"/contacts"}><T>contacts</T></RouterLink></Typography>
            </MaterialAlert>
          </>
        )}
        </>
      }
    </>
  );
}

function VariantPaymentButtons({prop, transaction, setTransaction}) {
  const [variantButton, setVariantButton] = useState({
    bank: prop.defaults?.paymentmethod === "bank" ? "outlined" : "text", 
    card: prop.defaults?.paymentmethod === "card" ? "outlined" : "text",
  });

  if (transaction?.paymentmethod === "trial") {
    return;
  }

  const handleChangeVariantPay = event => {
    const variant = event.currentTarget.value;
    setTransaction({...transaction, [event.currentTarget.name]: variant});
    const newVariantButton = variant === "card" ? { bank: "text", card: "outlined" } : { bank: "outlined", card: "text" };
    setVariantButton(newVariantButton);
  }

  const getButtonsProps = (value, name) => {
    const { id, amount_rub, paymentmethod } = transaction;
    
    if (id === "create" && !amount_rub && paymentmethod === "trial") return { variant: "text", disabled: true, color: "grey" };
  
    return transaction[name] === value ? { variant: "contained", color: "orange" } : { variant: "outlined", color: "grey" };
  };

  return (
    <Stack sx={{flexDirection: "row", justifyContent: "center", gap: 2, mt: 2}}>
      <Button 
        color="grey" 
        {...getButtonsProps("card","paymentmethod")}
        variant={variantButton.card}
        sx={{width: 78, height: 78}}
        name="paymentmethod" 
        value="card" 
        onClick={handleChangeVariantPay}
      >
        <IconCreditCard/>
      </Button>
      {window.globalState?.user?.role_administrator && (
        <Button 
          color="grey" 
          {...getButtonsProps("bank","paymentmethod")}
          variant={variantButton.bank} 
          sx={{width: 78, height: 78}}
          name="paymentmethod" 
          value="bank" 
          onClick={handleChangeVariantPay}
        >
        <IconBank/>
      </Button>
      )}
      
    </Stack>
  );
}

function VariantPaymentText({prop, transaction}) {
  return (
    <Typography sx={{mt: 2, textAlign: "center"}}><strong><T>payment method</T>:</strong> {(() => {
      if (prop.data?.id === "create" && transaction.amount_rub === 0) return <T>not selected</T>
      else if (transaction?.paymentmethod) return <T>{"payment method " +transaction.paymentmethod}</T>
      else return <T>not selected</T>
    })()}</Typography>
  );
}

function Itog({prop, transaction}) {

  return (
    <>
      <Stack sx={{flexDirection: "row", justifyContent: "space-between"}}>
        <Typography component="div" variant='h6'><strong><T>itogo</T>:</strong><br/><strong>(<T>not NDC</T>)</strong></Typography>
        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <Typography component="div" variant='h6' sx={{whiteSpace: "nowrap", ml:2}}><strong>{formatNumber(transaction?.yookassa?.amount?.value.slice(0, -3) || transaction?.amount_rub || 0)} ₽</strong></Typography>
        </Box>
      </Stack>

      {transaction?.id !== "create" && (
        <Typography variant='subtitle2' sx={{mt: 1, fontStyle: "italic", maxWidth: "60%"}}><T>not auto renewal</T></Typography>
      )}
      
      {transaction?.type === "unlimitedFF" && (
        <>
          {
            transaction.paymentmethod==="bank" && 
            <Box sx={{display: "flex", justifyContent: "end"}}>
              <ToPdfButton sx={{my:1}} color="primary" url={"/api/transactions/"+prop.data._id+"/bill.html"}><T>download bill</T></ToPdfButton>
            </Box> 
          }
          {/* <Typography sx={{textAlign: "right", mr:0.5, mt: 0.5}}><T>transaction method</T>: <T>{"payment method "+transaction.paymentmethod}</T></Typography>
          <Typography sx={{textAlign: "right", mr:0.5}}><T>transaction status</T>: <T>{"transaction status "+prop.data?.status}</T></Typography> */}
        </>
      )}
    </>
  );
}

function getFinishBtn([prop, transaction, myerrors]) {
  
  const sx = {mt: 1, mx: "auto", display: "block", minWidth: "40%"};  

  const getText = () => {
    switch (transaction?.paymentmethod) {
      case "bank":
        return "create an account";
      case "card":
        return "pay";
      default:
        return "use"
    }
  }

  const renderButton = (onClick) => {
    return (
      <Button onClick={() => {prop.onSave(transaction); onClick(myerrors)} } 
        variant="contained" sx={sx}>
        <T>{getText()}</T>
      </Button>
    )
  };
  
  if (transaction.id === "createFF" && !!transaction?.unlimitedFF) {
    if (transaction?.dermatoscopes?.length) {
      if (transaction?.paymentmethod === "trial")  return  renderButton(prop.apply)
      if (transaction?.paymentmethod === "bank")   return  renderButton(prop.apply)
      if (transaction?.paymentmethod === "card")   return  renderButton(prop.save)
    }
  } else if (transaction?.id === "create" && (transaction?.paymentmethod === "bank" || transaction?.paymentmethod === "card") && (transaction?.amount_rub)) {
    return renderButton(transaction?.paymentmethod === "bank" ? prop.apply : prop.save);
  }
    
  return <Button disabled variant="contained" sx={sx}><T>{getText()}</T></Button>;
}