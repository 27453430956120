import React,{ useState,useRef,useContext, useEffect} from 'react';
//import { isEqual } from 'lodash';

import {T, getImageUrl,Context, Alert, isValidObjectId,naming, axios } from "../Globals.js"
import {Chip, TextField, Box, Checkbox, Stack, Typography,FormGroup,FormControlLabel,FormControl,Paper,FormHelperText,Tabs,Tab, Snackbar, IconButton, MenuItem } from '@mui/material';
import ItemTemplate from "../components/ItemTemplate";
import ReadOnlyField from "../components/ReadOnlyField";
import MyImage from "../components/Image";
import NumberField from "../components/NumberField";
import Radio from '@mui/material/Radio';
import HumanSelector from '../components/HumanSelector.js';
import { ResponsesItemView } from './ResponsesItem.js';
import {DermatoscopesItemShort} from "../pages/DermatoscopesItem"
import { useLocation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';


//import RequirementsPhoto from '../components/RequirementsPhoto.js';
import { calculateAge } from './PacientsItem.js';
import Help from '../components/Help.js';
import useValidation from '../hooks/useValidation.js';
import SuperAdminPanel from '../components/SuperAdminPanel.js';
// import { set } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import DiagnosisSelector from '../components/DiagnosisSelector.js';
import { DatePicker } from '@mui/x-date-pickers';
import Sex from '../components/Sex.js';
import Image from '../components/Image';

const items = [
  {
    label: <T>causes occurrence</T>,
    category: "causes",
    control: <Checkbox />,
    formControlLabels: {
      isTrauma: <T>trauma</T>,
      isSun: <T>sun exposure</T>,
      isHeredity: <T>heredity</T>,
      isNot: <T>no reason</T>
    },
    additional: true,
  },
  {
    label: <T>neoplasm changed</T>,
    category: "changesmela",
    control: <Checkbox />,
    formControlLabels: {
      isHeight: <T>growing fast</T>,
      isSize: <T>size changed</T>,
      isForm: <T>form changed</T>,
      isColor: <T>color changed</T>,
    },
    additional: true,
  },
  {
    label: <T>education treatment</T>,
    category: "treatment",
    control: <Radio />,
    formControlLabels: {
      yes: <T>request yes</T>,
      no: <T>no</T>,
    },
    additional: true,
  },
  {
    label: <T>subjective feelings</T>,
    category: "feel",
    control: <Checkbox />,
    formControlLabels: {
      isPain: <T>soreness</T>, 
      isItching: <T>itching</T>,
      isHeat: <T>burning sensation</T>,
      isBlood: <T>bleeding</T>,
      isNot: <T>not bother</T>,
    },
    additional: true,
  },
  {
    label: <T>number formations</T>,
    category: "countmela",
    control: <Radio />,
    formControlLabels: {
      isOne: <T>single</T>,
      // isMany: <T>many formations</T>,
      min: <T>min 50</T>,
      max: <T>max 50</T>,
    },
    additional: true,
  },
];


export function QPSORequest(props) {
  const params = useParams()
  const {globalState} = useContext(Context);
  let [data, setData] = useState({...props?.defaults });
  const [patient, setPatient] = useState({})
  const [micros, setMicros] = useState([])
  const user = window.glob("user");
  // const {myerrors, filtered} = useValidation(data, [...items.map(item => item.category), "human", "whenappear", "pacientid", "image"]);
  const {myerrors, filtered} = useValidation(data, [...items.map(item => item.category), "human", "whenappear", "DateOfBirth", "Gender", "phototype"]);
  // console.log("пустые поля: ", myerrors);
  
  //console.log(params)
  useEffect(() => {
    if (!params.idmacro || !params.idmarker) return;
    (async function(){
        let respMarker = await axios.get(`/api/qpmarkers/${params.idmacro}`)
        let respMicros = await axios.get(`/api/qpmicrosbymarker/${params.idmacro}/${params.idmarker}`)
       
        setMicros(respMicros.data.data)
        let respPatients = await axios.get(`/api/qppatients`)
        
        // eslint-disable-next-line
        let PatientId = respMarker.data.data.find(item => item.Id == params.idmarker).PatientId

        // eslint-disable-next-line
        let patient = respPatients.data.data.find(item => item.Id == PatientId)

        setData({...data, DateOfBirth: patient.DateOfBirth, Gender: patient.Gender, QPPatientId: patient.Id})
        setPatient(patient)
    })()
    // eslint-disable-next-line
  }, [params.idmacro, params.idmarker]);

  if (!params.idmacro || !params.idmarker) return <></>

  // console.log(patient)
  const sendHandler = async (params1) => {
    return new Promise((resolve,reject)=>{

      if (myerrors.length===0) {
        if (data?.so && !data.socount) {
          data.socount = 1;
        }
        data.idmacro = Number(params.idmacro);
        data.idmarker = Number(params.idmarker);
        data.status='sent';
        resolve();
      } else {
        Alert(<T>required error alert</T>).then(()=>{
          reject();
        })
      }
    })
  }
 
  if (data?.status==="sent" && data?.id !=="create") {
    return (
      // <ItemTemplate {...props} errors={errors} validator={validator}  svg={{maxWidth: "217", minWidth: "217"}}  table="requests" data={data} buttons={["close","print","pdf"]}  setData={setData}>
      <ItemTemplate {...props} patient={patient} myerrors={myerrors} filtered={filtered} svg={{maxWidth: "217", minWidth: "217"}}  table="requests" data={data} buttons={user?.role_superadmin ? ["close","print","pdf","delete"] : ["close","print","pdf"]} setData={setData}>
        {user.role_superadmin ?
          <RequestsItemEdit  />  
          :
          <RequestsItemViewWrapper  />
        }
      </ItemTemplate>
    )
  }
  else {
    return (
      // <ItemTemplate {...props} errors={errors} validator={validator}  title={<T>request item</T>} table="requests" data={data} defaults={{...props.defaults, dermatoscopeid:globalState.user.dermatoscopeid, status:"draft", so:true, socount:1}} setData={setData} buttons={["draft", "send", "cancel", "delete"]} onSend={sendHandler}>
      <ItemTemplate {...props} user={user} patient={patient} micros={micros} myerrors={myerrors} filtered={filtered} title={<T>request item</T>} table="requests" data={data} defaults={{dermatoscopeid:globalState.user.dermatoscopeid, ...props.defaults,  status:"draft", so:true, socount:1}} setData={setData} buttons={["draft", "send", "cancel", "delete"]} onSend={sendHandler}>
        <RequestsItemEdit  />
      </ItemTemplate>
    )
  }
}

export function RequestsItemEdit(prop) {
  const params = useParams();
  let {myerrors, filtered} = prop;
  const [markers, setMarkers] = useState([]);
  const [mainImages, setMainImages] = useState([]);
  if (!prop.data?.so) prop.setData({...prop.data, so:true});

  useEffect(() => {
    (async function() {
      if (prop.patient.Id) {
        
        const baselineResult = await fetch(`/api/qpbaseline/${prop.patient.Id}`).then(res => res.json());
        if (baselineResult.result === "ok") {
          setMainImages(baselineResult.data);
        }
  
        const markersResult = await fetch(`/api/qpmarkers/${params.idmacro}`).then(res => res.json());
        if (markersResult.result === "ok") {
          setMarkers(markersResult.data);
        }
      }

    })()
    // eslint-disable-next-line
  }, [prop.patient.Id]);
  // const [snackbarOpen, setSnackbarOpen] = useState(localStorage.getItem("AiImage") ? true : false);

  const handleChange = (event) => {
    prop.setData({...prop.data, [event.target.name]: event.target.value});
  }
  const handleUncontrolledChange = (event) => {
    prop.data[event.target.name] = event.target.value;
    /* prop.validator(event.target.value); */
    if (event.target.value?.length === 0) prop.setData((prevData) => ({...prevData, ...prop.data}));
    else filtered(event.target.name);
  }
  
  const handleAnswers = (category, event, uncontrolled=false) => {

    const [name, value, checked] = [event.target.name, event.target.value, event.target.checked];
    const onlyAnswer = ["treatment", "countmela"];

    prop.data[category] = {...prop.data[category] || {}};
    prop.data[category][name] = checked;

    if (onlyAnswer.includes(category) && name !== "additional") {
      for (let key of Object.keys(prop.data[category])) {
        if (key !== "additional") delete prop.data[category][key];
      }
      prop.data[category][name] = checked;
    }

    if (name === "additional" || name === "apparat" || name === "grid" || name === "mydevice") {
      prop.data[category] = {...prop.data[category], [name]: value};

      if (name === "apparat") delete prop.data[category]?.mydevice;
    }

    if (uncontrolled === false) prop.setData((prevData) => ({...prevData, ...prop.data}));
    else {
      if (prop.data[category].additional.length === 0) {
        prop.setData((prevData) => ({...prevData, ...prop.data}));
      } else {
        filtered(category);
      }
    }
  // eslint-disable-next-line
  }
//console.log(prop.micros)
  return (
    <Stack gap={2}>
      <SuperAdminPanel {...prop} handleChange={handleChange}/>
      <Typography variant='h6' color={"primary"}><T>pacient</T>: {prop.patient?.FirstName}&nbsp;{prop.patient?.LastName}</Typography>
      
      <Image 
        src={params.idmacro ? "/api/qpimage/"+params.idmacro : ""}
        alt="Main Image"
        markers={markers}
        activeImage={mainImages?.[0]?.Id}
        mainImages={mainImages}
      />
      {
        prop.micros && prop.micros.map(micro => 
        <Box sx={{marginTop: 4}}>
          <Image  key={micro.Id} src={micro.Id ? "/api/qpimage/"+micro.Id : ""} alt="Main Image"/>
        </Box>)
      }

      <Help><T>request descr</T></Help>
      

      {/* {prop.data?.diagnosis && <center><DiagnosisSelector data={prop.data}/></center>} */}
    
      <Help><T>request dermatoscope help</T></Help>

      <Box mt={1}>
          <TextField
            fullWidth
            label = {<T>dermatoscope othername</T>}
            name="dermatocsopeothername"
            defaultValue={prop.data?.dermatocsopeothername || ""}
            onChange={handleUncontrolledChange}
          />
      </Box>
      
      <Typography component="div" variant='h6'><T>humanselector location</T></Typography>
      <FormHelperText error>
        {myerrors.includes("human") ? <T>required</T>: ""}
      </FormHelperText>
      <HumanSelector  data={prop.data} setData={prop.setData} aria-readonly/>

      {/* {window.location.pathname !== "/airequest" && 
      <Snackbar 
        sx={{"& .MuiSnackbarContent-root": {minWidth: "fit-content !important"}}}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        open={snackbarOpen}
        message={<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <img 
            style={{ width: 50, height: 50, objectFit: 'contain' }} 
            src={localStorage.getItem("AiImage")} 
            alt="aipicture" 
          />
          <T>picture ai</T>
          </Box>
        }
        action={
          <>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={() => setSnackbarOpen(!!!snackbarOpen)}
              
            >
              <CloseIcon />
            </IconButton>
          </>
        }
      />
    } */}

    <Typography component="div" variant='h6'><T>request options</T></Typography>
      
      <Paper sx={{my: 1, backgroundColor: window.grayColor, p:2, display: "flex", flexDirection: "column", alignItems: "center"}}>
        <Stack>
          {/* <FormControlLabel control={<Switch color="orange"/>} label={<T>ai diagnostic</T>} name="ai" checked={prop.data?.ai || false} onChange={handleCheck} /> */}
          <Stack sx={{flexDirection: "row", alignItems: "center"}}>
            {/*<FormControlLabel control={<Switch color="orange"/>} label={<T>second opinion</T>} name="so" checked={prop.data?.so || false} onChange={handleCheck} />*/}
            {prop.data?.so && (
              <NumberField 
                label={<T>opinion count</T>}
                name="socount"
                data={prop.data}
                setData={prop.setData}
              />
            )}
          </Stack>
        </Stack>
      </Paper>
      
      <Help><T>request descr2</T></Help>

      {prop.data?.so && (
        <Stack sx={{flexDirection: "column", gap: 3, my: 2}}>

          <Box mt={1}>
            <Sex handleChange={handleChange} data={prop.data} />
            <FormHelperText error>
              {prop.myerrors.includes("Gender") ? <T>required</T> : ""}
            </FormHelperText>
          </Box>
          
          <Box mt={1}>
            <DatePicker
              label={<T>birth</T>}
              name="DateOfBirth"
              value={ prop?.data?.DateOfBirth ? dayjs(prop?.data?.DateOfBirth) : "" }
              format="DD-MM-YYYY"
              inputFormat="YYYY-MM-DD"
              onChange={(date) => {prop.setData({ ...prop.data, "DateOfBirth": dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ') })}}
              sx={{flex:3}}
              error={!!prop.myerrors.includes("DateOfBirth")}
            />
            <FormHelperText error>
              {prop.myerrors.includes("DateOfBirth") ? <T>required</T> : ""}
            </FormHelperText>
          </Box>

          <Box mt={1}>
            <TextField
              select
              fullWidth
              label={<T>phototype</T>}
              value={prop.data?.phototype || ""}
              name="phototype"
              onChange={handleChange}
              error={!!myerrors.includes("phototype")}
              helperText={myerrors.includes("phototype") ? <T>required</T> : ""}
            >
              <MenuItem value=""><T>unknown</T></MenuItem>
              <MenuItem value="1"><T>phototype 1</T></MenuItem>
              <MenuItem value="2"><T>phototype 2</T></MenuItem>
              <MenuItem value="3"><T>phototype 3</T></MenuItem>
              <MenuItem value="4"><T>phototype 4</T></MenuItem>
              <MenuItem value="5"><T>phototype 5</T></MenuItem>
              <MenuItem value="6"><T>phototype 6</T></MenuItem>
            </TextField>
          </Box>

          <Box> 
            <Typography component="div" variant='h6'>{"1. "}{<T>when appear</T>}</Typography>
            <TextField
              fullWidth
              name="whenappear"
              type={prop.data?.whenappear || ""}
              defaultValue={prop.data?.whenappear || ""}
              onChange={handleUncontrolledChange}
              error={!!myerrors.includes("whenappear")}
              helperText={myerrors.includes("whenappear") ? <T>required</T> : ""}
            />
          </Box>

          {items.map((item, k) => (
            <Question data={prop.data} key={item.category} myerrors={myerrors} index={k} item={item} answer={prop.data?.[item.category] || {}} handleAnswers={handleAnswers}/>
          ))}
        </Stack>
      )}

      <Box>
        <Typography component="div" variant='h6'><T>doctor comment</T></Typography>
        <TextField
          fullWidth
          name="comment"
          type={prop.data?.comment || ""}
          defaultValue={prop.data?.comment || ""}
          onChange={handleUncontrolledChange}
        />
      </Box>

      {/* отчеты на запрос */}
      {prop.data?._responses?.map((r)=>(
        <ResponsesItemView key={r._id} data={r} setData={prop.setData}/>
      ))}

    </Stack>
  )

}


const Question = (prop) => {

  return (
    <Box mt={2}>
      <Typography component="div" variant='h6'>{(prop.index + 2).toString() + ". "}{prop.item.label}</Typography>
      {/* <FormControl sx={{}} fullWidth component="fieldset" error={!!prop.errors[prop.item.category]}> */}
      <FormControl sx={{}} fullWidth component="fieldset" error={!!prop.myerrors.includes(prop.item.category)}>
        <FormHelperText>
          {prop.myerrors.includes(prop.item.category) ? <T>required</T> : ""}
        </FormHelperText>

        <FormGroup onChange={(event) => prop.handleAnswers(prop.item.category, event)} >
          {Object.keys(prop.item.formControlLabels).map((cl, key) => (
            <FormControlLabel key={key} checked={prop.answer?.[cl] || false} name={cl} control={prop.item.control} label={prop.item.formControlLabels?.[cl]}  />
          ))}
        </FormGroup>
        {prop.item?.additional && (
          <TextField
            label={<T>additional information</T>}
            name="additional"
            defaultValue={prop.answer?.additional || ""}
            variant="standard"
            onChange={(event) => {
              prop.handleAnswers(prop.item.category, event, true);
            }}
          />
        )}
      </FormControl>
    </Box>
  );
}



export function RequestsItemViewWrapper(prop) {
  const reportTemplateRef = useRef(null);

  return (
    <div ref={reportTemplateRef}>
      <RequestsItemView {...prop}/>
      {prop.data?.so &&
        <Answers {...prop} />
      }
      {prop.data?._responses?.map((r)=>(
        <ResponsesItemView key={r._id} data={r} setData={prop.setData}/>
      ))}
      {/* {prop.data?._ais?.map((r)=>(
        <AisItemView key={r._id} data={r}/>
      ))} */}

    </div>
  )
}


export function RequestsItemView(prop) {
  
  const location = useLocation();
  const [fullScreen, setFullScreen] = useState(false);
  // const filterItems = items.filter(item => item.category in prop.data);

  const scrollHandler = React.useCallback(() => {
    window.scrollTo({ top: 1 });
  }, []);

  function handleFullScreen() {
    setFullScreen(!fullScreen);
    
    if (fullScreen) {
      window.removeEventListener('scroll', scrollHandler);
    } else {
      scrollHandler();
      window.addEventListener('scroll', scrollHandler);
    }
  }
  
  return (
    <Box sx={{display: "flex", gap: 3, flexDirection: "column"}}>
      <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
        <Box sx={{gap: 2}}>
          <Typography variant='myDefault22'><T>data pacient</T></Typography>
          <Stack sx={{flexDirection:"row", gap: 2, alignItems: "center", justifyContent: "space-between", flexWrap: {xs:"wrap", md:"nowrap"}}}>
            <Box sx={{alignSelf: "flex-start", order:1, display: "flex", gap: 1, flexDirection: "column", minWidth: {xs:150,md:250}, width:100, "@media screen and (max-width: 350px)": {width: "100%"}}}>
              {(prop.data?._pacient?.name && !(/responses/).test(location.pathname)) && 
                <ReadOnlyField label={<T>fio</T>} value={naming(prop.data?._pacient, "pacients")} />
              }
              {prop.data?._pacient?.cardid && <ReadOnlyField label={<T>pacient card</T>} value={prop.data?._pacient.cardid} />}
              {prop.data?._pacient?.phototype && <ReadOnlyField label={<T>phototype</T>} value={<T>{"phototype "+prop.data?._pacient.phototype}</T>} />}
              {prop.data?._pacient?.birth && <ReadOnlyField label={<T>birth</T>} value={dayjs(prop.data?._pacient?.birth).format("DD.MM.YYYY")} />}
              {prop.data?._pacient?.birth && <ReadOnlyField label={<T>age</T>} value={calculateAge(prop.data?._pacient.birth)} />}
              {prop.data?.human && <ReadOnlyField label={<T>humanselector location response</T>} value={<T>{"humanselector "+prop.data?.human.part}</T>} />}
            </Box>
              <MyImage className={"printImg"} src={getImageUrl(prop.data, "image")} 
                sx={
                  fullScreen 
                    ? {position: "absolute", top: 0, left: 0, width: "100%", height: "100vh", zIndex:1200}
                    : {order:{xs:3,md:2}}
                } 
                handleFullScreen={handleFullScreen}
                />
            <HumanSelector data={prop.data} buttons={false} title={false} minWidth={130} sx={{order:{xs:2,md:3}}}/>
          </Stack>

          {prop.data?.diagnosis &&
            <Box sx={{mt: 4}}>
              <Typography variant='myDefault22'><T>result ai</T></Typography>
              <DiagnosisSelector data={prop.data} sx={{maxWidth: "fit-content"}}/>
            </Box>
          }
          
        </Box>
      </Box>
      
{/*
      <Box>
        {prop.data._responses?.map((r)=>(
          <Paper sx={{p:2, mb:2, backgroundColor:"#f6f6f6"}} key={r._id}>
            <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: 2}}>
              <Chip label={r._expert?.name} avatar={<Avatar src="" />} />
              <Stack sx={{flexDirection: "row"}}>
                {getChipResponse(r)}
              </Stack>
            </Box>

            {r.grade && <ReadOnlyField label={<T>grade</T>} value={r.grade} />}
            {r.diagnosis && <ReadOnlyField label={<T>differential diagnosis</T>} value={r.diagnosis} />}
            {r.recommendations && <ReadOnlyField label={<T>recommendations</T>} value={r.recommendations} />}
            {r.answer && <ReadOnlyField label={<T>answer</T>} value={r.answer} />}

  
          </Paper>
        ))}

        {prop.data._ais?.map((r,i)=>(
          <Paper sx={{p:2, mb:2, backgroundColor:"#f6f6f6"}} key={r._id}>
            <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: 2}}>
              <Stack sx={{flexDirection: "row", gap: 2}}>
                <Img src={getImageUrl(r,"ai")} />
                {getChipResponse(r)}
              </Stack>
            </Box>
            {r.comment && <ReadOnlyField label={<T>comment</T>} value={r.comment} />}
          </Paper>
        ))}
      </Box>
        */}
    </Box>
  )
}

export function Answers(prop) {
  const filterItems = items.filter(item => item.category in prop.data);

  return (
    <Stack sx={{gap: 1, mt:4}}>
      <Typography variant='myDefault22'><T>readonly request so</T></Typography>
      <ReadOnlyField label={<T>when appear</T>} index={"1. "} value={prop.data?.whenappear}   />
      {filterItems.map((item, key) => {
        const answers = Object.keys(prop.data[item.category]).map(key => item.formControlLabels[key]).filter(answer => answer !== undefined).map((answer, key) => <Chip key={key} label={answer} size="small"  />);
        return <ReadOnlyField index={`${key+2}. `} label={item.label} value={prop.data[item.category].additional} key={key} chips={answers} />
      })}
      <ReadOnlyField index={``} label={<T>doctor comment</T>} value={prop.data.comment}   />
      {
        isValidObjectId(prop.data.dermatoscopeid)?
          <DermatoscopesItemShort data={prop.data._dermatoscope}/>
        :
          <ReadOnlyField label={<T>dermatoscope model</T>} value={<T>{"dermatoscope model "+prop.data.dermatoscopeid}</T>} />
      }
       {prop.data.dermatoscopeid === "other" &&
          <ReadOnlyField
            label = {<T>dermatoscope othername</T>}

            value={prop.data?.dermatocsopeothername || ""}
          />
        }
    </Stack>
  );
}



export function RequestTabs(prop) {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Форма запроса" {...a11yProps(0)} />
          {/* {window.location.pathname !== "/airequest" && <Tab label="Отправить Снимок Ai" {...a11yProps(1)} />}
          {window.location.pathname !== "/airequest" && <Tab label="Ручная настройка" {...a11yProps(2)} />} */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <RequestPanel {...prop}/>
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={1}>
        <AiPanel {...prop}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel> */}
    </Box>
  );
}

export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}


export function AiPanel(prop) {
  const aiImage = localStorage.getItem("AiImage")
  const outclass_name = localStorage.getItem("AiDiagnosis")
  const confidence = localStorage.getItem("AiConfidence")

  useEffect(() => {
    if (aiImage) {
      window.stopStream();
      prop.data?._image 
        ? prop.setData({...prop.data, _image: aiImage})
        : prop.setData({...prop.data, image: aiImage})
    }
    // eslint-disable-next-line
  }, []);

  return (<DiagnosisSelector data={{outclass_name, confidence}}/>);
}

export function RequestPanel(prop) {

  const [snackbarOpen, setSnackbarOpen] = useState(localStorage.getItem("AiImage") ? true : false);

  return (<>
    {window.location.pathname !== "/airequest" && 
      <Snackbar 
        sx={{"& .MuiSnackbarContent-root": {minWidth: "fit-content !important"}}}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        open={snackbarOpen}
        message={<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <img 
            style={{ width: 50, height: 50, objectFit: 'contain' }} 
            src={localStorage.getItem("AiImage")} 
            alt="aipicture" 
          />
          <T>picture ai</T>
          </Box>
        }
        action={
          <>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={() => setSnackbarOpen(!!!snackbarOpen)}
              
            >
              <CloseIcon />
            </IconButton>
          </>
        }
      />
    }

    <Typography component="div" variant='h6'><T>request options</T></Typography>
      
      <Paper sx={{my: 1, backgroundColor: window.grayColor, p:2, display: "flex", flexDirection: "column", alignItems: "center"}}>
        <Stack>
          {/* <FormControlLabel control={<Switch color="orange"/>} label={<T>ai diagnostic</T>} name="ai" checked={prop.data?.ai || false} onChange={handleCheck} /> */}
          <Stack sx={{flexDirection: "row", alignItems: "center"}}>
            {/*<FormControlLabel control={<Switch color="orange"/>} label={<T>second opinion</T>} name="so" checked={prop.data?.so || false} onChange={handleCheck} />*/}
            {prop.data?.so && (
              <NumberField 
                label={<T>opinion count</T>}
                name="socount"
                data={prop.data}
                setData={prop.setData}
              />
            )}
          </Stack>
        </Stack>
      </Paper>

      
      <Help><T>request descr2</T></Help>


      {prop.data?.so && (
        <Stack sx={{flexDirection: "column", gap: 3, my: 2}}>

          <Box> 
            <Typography component="div" variant='h6'>{"1. "}{<T>when appear</T>}</Typography>
            <TextField
              fullWidth
              name="whenappear"
              type={prop.data?.whenappear || ""}
              defaultValue={prop.data?.whenappear || ""}
              onChange={prop.handleUncontrolledChange}
              error={!!prop.myerrors.includes("whenappear")}
              helperText={prop.myerrors.includes("whenappear") ? <T>required</T> : ""}
            />
          </Box>
          

          {items.map((item, k) => (
            <Question data={prop.data} key={item.category} myerrors={prop.myerrors} index={k} item={item} answer={prop.data?.[item.category] || {}} handleAnswers={prop.handleAnswers}/>
          ))}
        </Stack>
      )}
  </>);
}

