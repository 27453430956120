import React, { useEffect } from 'react';
//import { useLoaderData } from "react-router-dom";
import MyDataGrid from "../components/MyDataGrid"
//import { Button } from '@mui/material';
import { Box,  Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { T, t } from "../Globals.js"
import Heading from '../components/Heading';
const columns = [

  { field: 'PatientRecordnumber', headerName: t("QP PatientRecordnumber"), width:100},
  { field: 'LastName', headerName: t("QP LastName"), flex:1},
  { field: 'MiddleName', headerName: t("QP MiddleName"), flex:1},
  { field: 'FirstName', headerName: t("QP FirstName"), flex:1},

];  
    


export function QPPatientList() {

  useEffect(() => {
    window.glob("title", <T>QP patient list</T>)
  }, [])

  const create = () => {
//    window.navigate('/ffapparats/create');
  }


  return (
    <Box sx={{px: 2}}>
      <Fab sx={{position:"fixed", right:20, bottom:20}} onClick={create} variant="extended" color="primary"><AddIcon /><T>create</T></Fab>
      <Heading heading={<T>pacients</T>} style={{marginBottom:0}}></Heading>
      <MyDataGrid
        columns={columns}
        table="qppatients"
        linktable="qppatients2"
        maxWidth="1500px"
        
      />
    </Box>
  );
}