import React, { useEffect, useState } from 'react';
//import { useLoaderData } from "react-router-dom";
import MyDataGrid from "../components/MyDataGrid.js"
//import { Button } from '@mui/material';
import { Box, Fab, Alert as Alt } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { T, t } from "../Globals.js"
import Heading from '../components/Heading.js';
import dayjs from 'dayjs';

const columns = [
  { field: 'date', headerName: t("date"), width: 130, renderCell: (params) => {
    if (params.formattedValue) return dayjs(params.formattedValue).format("DD.MM.YYYY HH:mm");
  } },
  { field: '_clinic', headerName: t("clinic"), flex:1, valueGetter:(p)=>p.row._clinic?.name||'' },

  { field: 'amount_rub', headerName: t("amount rub"), width: 130, renderCell: (params) => {
    if (params.formattedValue) {
      const value = params.formattedValue;
      let color = "green";

      if (Math.sign(value) === -1 && Math.sign(value) !== 0) color = "red";
      return <span style={{color: color}}>{value}</span>
    };
  } },
  { field: 'paymentmethod', headerName: t("payment method"), width: 130 },
  { field: 'status', headerName: t("payment status"), width: 130, renderCell:(params)=>t("payment "+params.formattedValue) },
  { field: 'qpbillstatus', headerName: t("ff bill status"), width: 130, renderCell:(params)=>t("ff bill status "+params.formattedValue) },
];  

export function QPbillsList() {
  const [errorService, setErrorService] = useState(null);

  useEffect(() => {
    window.glob("title", <T>ff support</T>)
  }, [])

  const create = () => {
    window.navigate('/qpbills/create');
  }
  
  return (
    <Box sx={{px: 2}}>
      {!errorService 
        ? <>
        <Fab sx={{position:"fixed", right:20, bottom:90}} onClick={create} variant="extended" color="primary"><AddIcon /><T>create</T></Fab>
          <Heading heading={<T>qp bills</T>} style={{marginBottom:0}}></Heading>
          <MyDataGrid
            columns={columns}
            table="qpbills"
            setErrorService={setErrorService}
          />
        </>
        : <Box sx={{ maxWidth: window.maxWidth, width: "100%", margin: "20px auto" }}>
            <Alt severity="warning"><T>{errorService}</T></Alt>
          </Box>
      }
      
    </Box>
  );
}