import React, { useEffect, useState } from 'react';
//import { useLoaderData } from "react-router-dom";
import MyDataGrid from "../components/MyDataGrid"
//import { Button } from '@mui/material';
import { Box, FormControlLabel, Switch, Typography, Alert as Alt } from '@mui/material';
import { Alert, T, axios, t } from "../Globals.js"
import Heading from '../components/Heading';
import { QPLicense } from '../components/QPLicense.js';

   
export function QPDeviceList() {
  const [forceUpdate, setForceUpdate] = useState(0)
  const [errorService, setErrorService] = useState(null);
  useEffect(() => {
    window.glob("title", <T>QP device list</T>)
  }, [])

   
  const columns = [

    { field: 'DeviceId', headerName: t("QP DeviceId"), flex:1},
    { field: 'DeviceName', headerName: t("QP DeviceName"), flex:1},
    { color:"primary", field: 'Authorized', headerName:  t("QP Authorized"), flex:1, renderCell:(data)=>{

      return <Box gap={5}>
        <FormControlLabel 
          control={<Switch />} 
          onChange = {async ()=>{
            let newValue = data.row.Authorized==="yes"?"no":"yes"
            let result = await axios.post("/api/qpdevices",{DeviceId: data.row.DeviceId, Authorized: newValue})
            if (result.data.result === "error") {
              Alert(t(result.data.message))
            }
            setForceUpdate(Math.random())
          }}
          checked={data.row.Authorized==='yes'?true:false}
        />
      </Box>
    }}
  ];  

  return (
    <Box sx={{px: 2}}>
      <Heading heading={<T>ff apparats</T>} style={{marginBottom:0}}></Heading>
      {!errorService 
        ? (
          <>
            <QPLicense />
            <MyDataGrid
              columns={columns}
              table="qpdevices"
              linktable="qpdevices"
              maxWidth="1500px"
              onRowClick={()=>{}}
              forceUpdate = {forceUpdate}
              setErrorService={setErrorService}
            />
          </>
        )
        : <Box sx={{ maxWidth: window.maxWidth, width: "100%", margin: "20px auto" }}>
          <Alt severity="warning"><T>{errorService}</T></Alt>
        </Box>
      }
    </Box>
  );
}

