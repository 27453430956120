import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, Button, CircularProgress, Paper } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { ZoomIn as ZoomInIcon, ZoomOut as ZoomOutIcon, ZoomOutMap as ZoomOutMapIcon, ZoomInMap as ZoomInMapIcon } from '@mui/icons-material';

const Controls = ({ zoomIn, zoomOut, resetTransform, handleFullScreen, zoomInRef, zoomOutRef }) => (
  <Box className="imageControls" sx={{ position: "absolute", top: 0, left: 0, zIndex: 1999, display: "flex", gap: 1, m: 1 }}>
    <Button ref={zoomInRef} variant="contained" onClick={() => zoomIn()}><ZoomInIcon /></Button>
    <Button ref={zoomOutRef} variant="contained" onClick={() => zoomOut()}><ZoomOutIcon /></Button>
    <Button variant="contained" onClick={() => resetTransform()}><ZoomOutMapIcon /></Button>
    <Button variant="contained" onClick={() => handleFullScreen()}><ZoomInMapIcon /></Button>
  </Box>
);

function Image({ src, alt, markers = [], activeImage, mainImages, onLoad, sx = {} }) {
  const [loaded, setLoaded] = useState(false);
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [transform, setTransform] = useState({ scale: 1, positionX: 0, positionY: 0 });
  const [height, setHeight] = useState('100px');
  const [width, setWidth] = useState('100px');
  const imageRef = useRef(null);
  const zoomInRef = useRef(null);
  const zoomOutRef = useRef(null);

  const updateHeight = () => {
    if (window.innerWidth <= 768) {
      setHeight('50%'); // Высота для моб. устр.
    } else {
      setHeight('100%'); // Высота для десктоп
    }
  };
  const updateWidth = () => {
    if (window.innerWidth <= 768) {
      setWidth('500px');
    } else {
      setWidth('100%');
    }
  };
  const updateCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    const selectedImage = mainImages?.find(img => img._id === activeImage)
    if (imageRef.current && canvasRef.current && selectedImage) {
      const imageHeight = imageRef.current.clientHeight;
      const imageWidth = imageRef.current.clientWidth;
      canvas.width = imageWidth;
      canvas.height = imageHeight;
      const scaleX = imageWidth / selectedImage.ImageWidth;
      const scaleY = imageHeight / selectedImage.ImageHeight;
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      markers.forEach(marker => {
        const scaledX1 = marker.MarkPoint1X * scaleX;
        const scaledY1 = marker.MarkPoint1Y * scaleY;
        const scaledX2 = marker.MarkPoint2X * scaleX;
        const scaledY2 = marker.MarkPoint2Y * scaleY;
        drawArrow(scaledX1, scaledY1, scaledX2, scaledY2, marker.MarkText);
      });
    }
  }, [markers, activeImage, mainImages]);

  const handleImageLoad = useCallback(() => {
    setLoaded(true);
    onLoad?.();
    if (document.fullscreenElement && !(window.innerWidth <= 768)) {
      imageRef.current.style.width = '800px';
      imageRef.current.style.height = '150%';
    } else {
      imageRef.current.style.width = '320px';
      imageRef.current.style.height = '470px';
    }
    handleClickBtnOnLoad();
  }, [onLoad, imageRef]);

  useEffect(() => {
    if (src) {
      updateCanvas();
    }
    updateHeight();
    updateWidth();
    window.addEventListener('resize', updateHeight);
    window.addEventListener('resize', updateWidth);
    document.addEventListener('fullscreenchange', handleImageLoad);
    return () => {
      window.removeEventListener('resize', updateHeight);
      window.removeEventListener('resize', updateWidth);
      document.removeEventListener('fullscreenchange', handleImageLoad);
    };
  }, [src, transform, loaded, markers, updateCanvas, handleImageLoad]);

  const drawArrow = (x1, y1, x2, y2, markerId) => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    const headLength = 15;

    ctx.beginPath();
    ctx.moveTo(x2, y2);
    ctx.lineTo(x1, y1);
    ctx.lineWidth = 2;
    ctx.strokeStyle = 'aqua';
    ctx.stroke();

    const angle = Math.atan2(y1 - y2, x1 - x2);
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x1 - headLength * Math.cos(angle - Math.PI / 6), y1 - headLength * Math.sin(angle - Math.PI / 6));
    ctx.moveTo(x1, y1);
    ctx.lineTo(x1 - headLength * Math.cos(angle + Math.PI / 6), y1 - headLength * Math.sin(angle + Math.PI / 6));
    ctx.stroke();
    ctx.fillStyle = 'aqua';
    ctx.font = '14px Arial';
    ctx.fillText(`[${markerId}]`, x2 + 5, y2 - 5);
  };

  const handleClickBtnOnLoad = () => {
    setTimeout(() => {
      if (zoomInRef.current && zoomOutRef.current) {
        zoomInRef.current.click();
        setTimeout(() => {
          zoomOutRef.current.click();
        }, 50);
      }
    }, 50);
  };

  const handleFullScreen = () => {
    const container = containerRef.current;
    if (!document.fullscreenElement) {
      if (container) {
        if (container.requestFullscreen) {
          container.requestFullscreen();
        } else if (container.webkitRequestFullscreen) {
          container.webkitRequestFullscreen();
        } else if (container.mozRequestFullScreen) {
          container.mozRequestFullScreen();
        } else if (container.msRequestFullscreen) {
          container.msRequestFullscreen();
        }
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  return (
    <Paper
      elevation={1}
      ref={containerRef}
      bgcolor="white"
      sx={{
        position: "relative",
        mb: 2,
        width: "auto",
        height: "60vh",
        border: "2px solid #686895",
        margin: "-12px 0 0",
        ...sx,
      }}
    >
      {!loaded && src && <CircularProgress sx={{ position: "absolute", margin: "25% 50%" }} />}
      {src ? (
        <TransformWrapper
          initialScale={1}
          minScale={1}
          maxScale={3}
          onZoomChange={(ref) => setTransform(ref.state)}
          onPanning={(ref) => setTransform(ref.state)}
          wrapperStyle={{ width: "100%", height: "100%" }}
          contentStyle={{ maxWidth: width, height: height }}
          onDragStart={(e) => e.preventDefault()}
        >
          {(utils) => (
            <>
              <Controls
                zoomIn={utils.zoomIn}
                zoomOut={utils.zoomOut}
                resetTransform={utils.resetTransform}
                handleFullScreen={handleFullScreen}
                zoomInRef={zoomInRef}
                zoomOutRef={zoomOutRef}
              />
              <TransformComponent wrapperStyle={{ height: "100%", width: "100%" }}>
                <img
                  ref={imageRef}
                  src={src}
                  alt={alt}
                  onLoad={handleImageLoad}
                  style={{ objectFit: "contain", height: "100%" }}
                /> {markers.length > 0 && (
                  <canvas
                    ref={canvasRef}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      pointerEvents: 'none',
                      zIndex: 2,
                      width: '100%',
                      height: "100%",
                    }}
                  />)}
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      ) : (
        <ImageIcon sx={{ fontSize: 200 }} />
      )}
    </Paper>
  );
}

export default Image;
