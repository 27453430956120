import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Alert, formatNumber, T, t } from "../Globals.js"
import { Alert as MaterialAlert, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import {
  Box,
  Typography,
  Button,
  TextField,
  Tab,
  Tabs,
  Card,
  CardContent,
  Chip,
  Divider,
  Stack,
} from "@mui/material"
import { ReactComponent as IconBank } from "../icons/bank.svg";
import { ReactComponent as IconCreditCard } from "../icons/creditcard.svg";
import dayjs from 'dayjs';


export const QPLicense = () => {
  const clinic = window.glob("user")._clinic

  const [additionalCount, setAdditionalCount] = useState(1)
 // const [licensePeriod, setLicensePeriod] = useState("year")
  const [newLicenseCount, setNewLicenseCount] = useState(window.glob("user")._clinic?.qpcount || 1)
  const [newLicensePeriod, setNewLicensePeriod] = useState("year")
  const [costData, setCostData] = useState({})
  const [additionalСostData, setAdditionalCostData] = useState({})
  const [transactions, setTransactions] = useState([])
  const [paymentMethod, setPaymentMethod] = useState("card")
  const [tabIndex, setTabIndex] = useState(0)


  //const startDate = new Date(clinic.qpstartdate)
  let endDate = clinic.qpenddate ? new Date(clinic.qpenddate) : null
  // const now = new Date()

  //const isLicenseActive = endDate && !isNaN(endDate) && endDate > now
  /*
    const handleExtendLicense = () => {
      // Логика продления лицензии
      console.log(`Extend license by ${licensePeriod} days for ${clinic.qpcount} devices`)
    }
  */
  const handleAddCount = () => {
    // Логика добавления устройств
    console.log(`Add ${additionalCount} devices to current license`)
    axios.post("/api/transactions", { id: "createQPADD", qpaddcount: additionalCount, paymentmethod: paymentMethod }).then(resp => {
      if (resp.data.result === "ok") {
        if (resp.data?.data?.yookassa?.confirmation?.confirmation_url) {
          document.location.href = resp.data.data.yookassa.confirmation.confirmation_url;
        } else {
          document.location.href = `/transactions/${resp.data.data._id}`
          //alert("mmmmm")
          //document.location.reload()
        }
      } else if (resp.data.result === "error") {
        Alert(resp.data.message)
      }
    })

  }
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  const handleNewLicense = () => {
    // Логика покупки новой лицензии
    console.log(`Purchase new license for ${newLicenseCount} devices for ${newLicensePeriod} days`)
    axios.post("/api/transactions", {id:window.glob("user")._clinic.qpenddate ? "createQPPROLONG":"createQP",  qplicenseperiod:newLicensePeriod, qplicensecount:newLicenseCount, paymentmethod:paymentMethod }).then(resp => {
      if (resp.data.result==="ok") {
        if (resp.data?.data?.yookassa?.confirmation?.confirmation_url) {
          document.location.href = resp.data.data.yookassa.confirmation.confirmation_url;
        } else {
          document.location.href = `/transactions/${resp.data.data._id}`
          //alert("mmmmm")
          //document.location.reload()
        }
      } else if (resp.data.result === "error") {
        Alert(resp.data.message)
      }
    })

  }

  //получение стоимости выбранных услуг
  useEffect(() => {
    axios.post("/api/transactions/calc", { qplicenseperiod: newLicensePeriod, qplicensecount: newLicenseCount }).then(resp => {
      setCostData(resp.data.data);
    })
  }, [newLicenseCount, newLicensePeriod])

  //получение стоимости выбранных услуг
  useEffect(() => {
    axios.post("/api/transactions/calc", { qpaddcount: additionalCount }).then(resp => {
      setAdditionalCostData(resp.data.data);
    })
  }, [additionalCount])

  //получения списка всех транзакций что бы найти в них выставленные но не оплаченные счета за QP
  useEffect(() => {
    axios.get("/api/transactions").then(resp => {
      setTransactions(resp.data.data);
    })
  }, [])

  // console.log(transactions);


  return (
    <Box sx={{ maxWidth: window.maxWidth, width: "100%", margin: "20px auto" }}>

      <Box sx={{ maxWidth: { xs: 320, sm: 768 }, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={handleTabChange} scrollButtons allowScrollButtonsMobile variant="scrollable">
          <Tab label={t("QP status")} value={0} />
          <Tab label={t("QP add devices")} value={1} />
          <Tab label={t("QP purchase new")} value={2} />
        </Tabs>
      </Box>

      <CustomTabPanel value={tabIndex} index={0}>
        {!endDate || isNaN(endDate) ? (
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h6" color="primary"><T>subscription</T></Typography>
              <Divider className='mb-4' />

              {transactions && transactions.filter(t => t.type === "qplicense").map((t, i) => {

                return (
                  <Stack gap={2} className='md:w-1/2'>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell className='p-1'><Typography><T>date</T>:</Typography></TableCell>
                            <TableCell className='p-1'><Typography>{dayjs(t.date).format("DD.MM.YYYY HH:mm")}</Typography></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className='p-1'><Typography><T>transaction status</T>:</Typography></TableCell>
                            <TableCell className='p-1'><Typography><T>{"transaction status " + t.status}</T></Typography></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className='p-1'><Typography><T>transaction method</T>:</Typography></TableCell>
                            <TableCell className='p-1'><Typography><T>{t?.paymentmethod}</T></Typography></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className='p-1'><Typography><T>onetime tariff</T>:</Typography></TableCell>
                            <TableCell className='p-1'><Typography><T>{t?.qplicenseperiod}</T></Typography></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className='p-1'><Typography><T>QP count devices</T>:</Typography></TableCell>
                            <TableCell className='p-1'><Typography><T>{t?.qplicensecount}</T></Typography></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className='p-1'><Typography><T>bill status</T>:</Typography></TableCell>
                            <TableCell className='p-1'><Typography><T>{t?.qpbillstatus}</T></Typography></TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <MaterialAlert severity="info" color='warning'><T>wait confirmation payment</T></MaterialAlert>

                    <Stack sx={{ flexDirection: "row", justifyContent: "start", my: 2, gap: 5, }}>
                      <Typography component="div" variant='h6'><strong><T>itogo</T>:</strong><br /><strong>(<T>not NDC</T>)</strong></Typography>
                      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography component="div" variant='h6' sx={{ whiteSpace: "nowrap", ml: 2 }}><strong>{formatNumber(t?.price_qp || 0)} ₽</strong></Typography>
                      </Box>
                    </Stack>
                  </Stack>
                )

              })}
            </CardContent>
          </Card>
        ) : (
          <Card className='w-fit'>
            <CardContent>
              <Typography gutterBottom variant="h6" color="primary"><T>subscription</T></Typography>
              <Divider />
              <div className='grid grid-cols-2 mt-4'>
                <div className='flex flex-col mt-2 gap-1'>
                  <Typography variant="body1" className='mr-2'><T>valid until</T>:</Typography>
                  <Typography variant="body1"><T>QP count</T>:</Typography>
                </div>
                <div className='flex items-center flex-col mt-2 gap-1'>
                  <Chip size='small' variant='outlined' label={<Typography variant='body2'>{endDate.toLocaleDateString()}</Typography>} color="primary" />
                  <Typography variant="body1">{clinic?.qpcount}</Typography>
                </div>
              </div>
            </CardContent>
          </Card>
        )}
      </CustomTabPanel>

      <CustomTabPanel value={tabIndex} index={1}>
        <Card className='w-1/2 min-w-fit'>
          <CardContent>
            <Typography gutterBottom variant="h6" fontWeight={"bold"}><T>QP add count</T></Typography>
            <Divider />

            <Stack gap={2}>
              <CountDevices newCount={newLicenseCount} min={window.glob("user")._clinic?.qpcount} setNewCount={setNewLicenseCount} title={<T>select number devices</T>} />
              <TariffPeriod newLicensePeriod={newLicensePeriod} setNewLicensePeriod={setNewLicensePeriod} title={<T>select tariff</T>} />
              <VariantPaymentButtons paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} title={<T>select payment method</T>} />
              <Stack sx={{ flexDirection: "row", justifyContent: "space-between", my: 2 }}>
                <Typography component="div" variant='h6'><strong><T>itogo</T>:</strong><br /><strong>(<T>not NDC</T>)</strong></Typography>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Typography component="div" variant='h6' sx={{ whiteSpace: "nowrap", ml: 2 }}><strong>{formatNumber(costData?.amount_rub || 0)} ₽</strong></Typography>
                </Box>
              </Stack>

              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleNewLicense}
              >
                <T>QP buy</T>
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </CustomTabPanel>

      {/* Добавление устройств */}
      <CustomTabPanel value={tabIndex} index={2}>
        <Card className='w-1/2 min-w-fit'>
          <CardContent>
            <Typography gutterBottom variant="h6" fontWeight={"bold"}><T>QP purchase new</T></Typography>
            <Divider />

            <Stack gap={1}>
              <CountDevices newCount={additionalCount} setNewCount={setAdditionalCount}
                helperText={
                  <span className='flex flex-col'>
                    <span className='flex items-center'><T>QP calculation</T>: {additionalСostData?.qpdays}<T>dayss</T> &times; {additionalСostData?.price_qpadd}₽ &times; {additionalCount}<T>things</T></span>
                    <span className='flex items-center underline'><T>QP total count</T>: <Typography className='ml-1' component={"span"}>{+ parseInt(clinic?.qpcount) + parseInt(additionalCount)}</Typography></span>
                  </span>
                } />

              <VariantPaymentButtons paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} />
              <Stack sx={{ flexDirection: "row", justifyContent: "space-between", my: 2 }}>
                <Typography component="div" variant='h6'><strong><T>itogo</T>:</strong><br /><strong>(<T>not NDC</T>)</strong></Typography>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Typography component="div" variant='h6' sx={{ whiteSpace: "nowrap", ml: 2 }}><strong>{formatNumber(additionalСostData?.amount_rub || 0)} ₽</strong></Typography>
                </Box>
              </Stack>

              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleAddCount}
              >
                <T>QP buy</T>
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </CustomTabPanel>
    </Box>
  )
}



function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>{children}</Box>
      )}
    </div>
  )
}

function CountDevices({ newCount, setNewCount, helperText, min=1, title = <T>select number devices</T>, subtitle = <T>QP additional_devices</T> }) {
  return (
    <div>
      <Typography variant='body1' color={"black"} sx={{ mt: 2, mx: "auto" }}>{title}</Typography>
      <TextField
        className=' w-full max-w-sm'
        type="number"
        label={subtitle}
        value={newCount}
        inputProps={{ min: min, max: 100 }}
        onChange={(e) => setNewCount(e.target.value)}
        helperText={helperText}
      />
    </div>
  );
}

function TariffPeriod({ newLicensePeriod, setNewLicensePeriod, title = <T>select tariff</T>, onlyOne = false }) {

  const arrayTarifs = [
    { v: "trial 30", t: "QP period_30days" },
    { v: "quartal", t: "QP period_90days" },
    { v: "year", t: "QP period_1year" },
  ];

  const getButtonsProps = (selectedVariant) => {
    return newLicensePeriod === selectedVariant ? { variant: "contained", color: "primary" } : { variant: "outlined", color: "grey" };
  }

  const handleChangeVariantPay = (e) => {
    setNewLicensePeriod(e.target.value);
  }

  const getBtns = (variantTarif) => {
    return (
      <Button
        key={variantTarif.v}
        {...getButtonsProps(variantTarif.v)}
        sx={{ width: "40%", fontWeight: 600, minHeight: 70 }}
        value={variantTarif.v}
        onClick={handleChangeVariantPay}
      >
        <T>{variantTarif.t}</T>
      </Button>
    );
  }

  return (
    <div>
      <Typography variant='body1' color={"black"} sx={{ mt: 2, mb: 1, mx: "auto" }}>{title}</Typography>
      <Stack sx={{ flexDirection: "row", justifyContent: onlyOne ? "start" : "space-around", mt: 1, gap: 2 }}>
        {arrayTarifs
          .filter(variantTarif => onlyOne ? newLicensePeriod === variantTarif.v : true)
          .map(variantTarif => getBtns(variantTarif))}
      </Stack>
    </div>
  );
}

function VariantPaymentButtons({ paymentMethod, setPaymentMethod, title = <T>select payment method</T>, onlyOne = false }) {
  const arrayPayment = [
    { v: "card", t: <IconCreditCard className="pointer-events-none" /> },
    { v: "bank", t: <IconBank className="pointer-events-none" /> },
  ];

  const getButtonsProps = (selectedPayment) => {
    return paymentMethod === selectedPayment
      ? { variant: "contained", color: "secondary" }
      : { variant: "outlined", color: "grey" };
  };

  const handleChangeVariantPay = (e) => {
    setPaymentMethod(e.currentTarget.value);
  };

  const getBtns = (variantPayment) => {
    return (
      <Button
        key={variantPayment.v}
        {...getButtonsProps(variantPayment.v)}
        sx={{ width: 78, height: 78 }}
        value={variantPayment.v}
        onClick={handleChangeVariantPay}
      >
        {variantPayment.t}
      </Button>
    );
  };

  return (
    <div>
      <Typography variant="body1" color="black" sx={{ mt: 2, mb: 1, mx: "auto" }}>{title}</Typography>
      <Stack sx={{ flexDirection: "row", justifyContent: "start", mt: 1, gap: 2, }}>
        {arrayPayment
          .filter(variantPayment => onlyOne ? paymentMethod === variantPayment.v : true)
          .map(variantPayment => getBtns(variantPayment))}
      </Stack>
    </div>
  );
}