import React, { useState, useEffect, useContext } from 'react';
import { Box, Stack, SvgIcon, Typography } from '@mui/material';
import { axios, Context } from "../../Globals.js";
// import {ReactComponent as IconAi} from "../../icons/ai.svg";
import { ReactComponent as IconSo } from "../../icons/so.svg";
import { ReactComponent as IconAi } from "../../icons/ai.svg";
import dayjs from 'dayjs';
import Refill from '../Refill.js';
import {T, numWord} from '../../Globals.js';



function Balance(props) {
  // const {globalState} = useContext(Context);
  const [firstDermatoscop, setFirstDermatoscop] = useState(null);
  const { globalState } = useContext(Context);
  const qpCount = window.glob("user")?._clinic?.qpcount;
  const result = numWord(qpCount, "устройство", "устройства", "устройств");
  const qpenddate = window.glob("user")?._clinic.qpenddate;
  const qpnowdate = new Date();
  const nowDate = `${qpnowdate.getFullYear()}-${String(qpnowdate.getMonth() + 1).padStart(2, '0')}-${String(qpnowdate.getDate()).padStart(2, '0')}`;

  useEffect(() => {
    axios.get(`/api/dermatoscopes`).then((resp) => {
      if (resp.data.data) {
        const apparats = resp.data.data.filter((device) => new Date(device.enddate).getTime() >= new Date().getTime());
        const sortApparats = apparats.sort((a, b) => new Date(a.enddate).getTime() - new Date(b.enddate).getTime());
        if (sortApparats[0]?.enddate) setFirstDermatoscop(sortApparats[0].enddate);
      }
    }).catch(() => { });
    
    // eslint-disable-next-line
  }, []);

  return (
    <Stack sx={{ mb: { xs: 5, md: 0 }, height: "100%", p: 2, alignItems: "center", justifyContent: "center", gap: 2.7, backgroundColor: window.theme.palette.white.v2, width: "70%", margin: "0 auto" }}>
      <Typography textAlign="left" component="div" variant='myDefault22'><T>current tariff</T></Typography>
      <Stack sx={{ gap: 2.7, flexDirection: "column", justifyContent: "space-between" }} >
        {qpCount && qpenddate !== undefined && qpenddate > nowDate ?
          (<Box sx={{ borderRadius: 1, display: "flex", gap: 1, alignItems: "center" }}><SvgIcon fontSize='large'>
            <IconAi />
          </SvgIcon>
            <Typography sx={{ ml: 1 }} color="black"><T>tariff registration</T> {result} <T>tariff registration__part2</T><strong>{dayjs(qpenddate).format("DD.MM.YYYY")}</strong></Typography>
          </Box>)
          : (
            <span><T>no current_tariff</T></span>
          )}

      </Stack>
      <Stack sx={{ gap: 2.7, flexDirection: "column", justifyContent: "space-between" }} >
        {firstDermatoscop &&
          <Box sx={{ borderRadius: 1, display: "flex", gap: 1, alignItems: "center" }}>
            <SvgIcon fontSize='large'>
              <IconAi />
            </SvgIcon>
            <Typography sx={{ ml: 1 }} color="black"><T>unlimited tariff</T><strong>{dayjs(firstDermatoscop).format("DD.MM.YYYY")}</strong></Typography>
          </Box>
        }

        {window?.features?.so &&
          <Box sx={{ borderRadius: 1, display: "flex", gap: 1, alignItems: "center" }}>
            <SvgIcon fontSize='large'>
              <IconSo />
            </SvgIcon>
            <Typography sx={{ ml: 1 }} color="black"><T>count_requests</T><strong>{globalState?.balance.so || "0"}</strong></Typography>
          </Box>
        }
      </Stack>

      <Refill btnProps={{ color: "secondary" }} />

    </Stack>
  );
}

export default Balance;
